<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <!--        <left-bar-comp>-->
        <!--            <sports-left-bar-comp></sports-left-bar-comp>-->
        <!--        </left-bar-comp>-->

        <div class="content_section">
            <div class="content">
                <sub-title>입금</sub-title>

                <div class="main_panel">
<!--                    <div class="re_cont">-->
<!--                        <div class="c1">-->
<!--                            보유머니-->
<!--                        </div>-->
<!--                        <div class="c2" style="">-->
<!--                            <span>{{$store.state.userInfo.cash|comma}}</span>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="re_cont">
                        <div class="c1">
                            입금명
                        </div>
                        <div class="c2" style="">
                            <span>{{$store.state.userInfo.beneficiary}}</span>
                        </div>
                    </div>
                    <div class="re_cont">
                        <div class="c1">
                            금액
                        </div>
                        <div class="c2" style="">
                            <div style="display: flex;justify-content: flex-start;align-items: center;padding: 0px 0">
                                <input type="text" v-model="applyAmount" class="re_input" placeholder="충전금액"
                                       @input="setComma">
                                <button type="button" class="btn-re2" @click="changeMoney(0)"
                                        style="margin-left: 6px">삭제
                                </button>
                            </div>
                        </div>
                    </div>
                    <!--             <div class="re_cont">
                                     <div class="c1">
                                         빠른입력
                                     </div>
                                     <div class="c2" style="">
                                         <div style="display: flex; justify-content: flex-start;width: 100%;padding: 0px 0;flex-wrap: wrap">
                                             <button type="button" class="btn-re" @click="changeMoney(10000)">
                                                 1만원
                                             </button>
                                             <button type="button" class="btn-re" @click="changeMoney(30000)">
                                                 3만원
                                             </button>
                                             <button type="button" class="btn-re" @click="changeMoney(50000)">
                                                 5만원
                                             </button>
                                             <button type="button" class="btn-re" @click="changeMoney(100000)">
                                                 10만원
                                             </button>
                                             <button type="button" class="btn-re" @click="changeMoney(500000)">
                                                 50만원
                                             </button>
                                             <button type="button" class="btn-re" @click="changeMoney(1000000)">
                                                 100만원
                                             </button>

                                         </div>
                                     </div>
                                 </div>-->
                    <div class="re_cont">
                        <div class="c1">
                            입금계좌
                        </div>
                        <div class="c2" style="">
                            <div style="display: flex;justify-content: flex-start;align-items: center;padding: 0px 0">
                                <button type="button" class="btn-re3" @click="questioAcNo">
                                   계좌문의
                                </button>
                            </div>
                        </div>
                    </div>
                    <!--<div class="re_cont">
                        <div class="c1">
                            롤링페센트
                        </div>
                        <div class="c2" style="">
                            &lt;!&ndash;롤링 보너스 적용&ndash;&gt;
                            <div class="bonus1" v-if="applyCash.bonusin == sportsConst.APPLY_BONUS_YES1">
                                <div style="font-size: 10px">
                                    <p>
                                        스포츠 <span class="percentStyle">100%</span>
                                        인플레이 <span class="percentStyle">200%</span>
                                        스페셜 <span class="percentStyle">200%</span>
                                    </p>
                                    <p>
                                        단/두폴더 <span class="percentStyle">200%</span>
                                        미니게임 <span class="percentStyle">300%</span>
                                        카지노&슬롯 <span style="color: red;font-weight: bold">이용불가</span>
                                    </p>
                                </div>
                            </div>
                            &lt;!&ndash;롤링 보너스 미적용&ndash;&gt;
                            <div class="bonus2" v-if="applyCash.bonusin == sportsConst.APPLY_BONUS_NOT">
                                <div style="font-size: 10px">
                                    <p>
                                        스포츠 <span class="percentStyle">100%</span>
                                        인플레이 <span class="percentStyle">100%</span>
                                        스페셜 <span class="percentStyle">100%</span>
                                    </p>
                                    <p>
                                        단/두폴더 <span class="percentStyle">100%</span>
                                        미니게임 <span class="percentStyle">100%</span>
                                        카지노&슬롯 <span class="percentStyle">100%</span> <span
                                            style="color: skyblue;font-weight: bold">이용가능</span>
                                    </p>
                                </div>
                            </div>

                            &lt;!&ndash;롤링 보너스 적용(Lv2이상 가능)&ndash;&gt;
                            <div class="bonus1" v-if="applyCash.bonusin == sportsConst.APPLY_BONUS_YES3">
                                <div style="font-size: 10px">
                                    <p>
                                        스포츠 <span class="percentStyle">100%</span>
                                        인플레이 <span class="percentStyle">200%</span>
                                        스페셜 <span class="percentStyle">200%</span>
                                    </p>
                                    <p>
                                        단/두폴더 <span class="percentStyle">200%</span>
                                        미니게임 <span class="percentStyle">300%</span>
                                        카지노&슬롯 <span class="percentStyle">300%</span> <span
                                            style="color: skyblue;font-weight: bold">이용가능</span>
                                    </p>
                                </div>
                            </div>

                            &lt;!&ndash;롤링 보너스 적용(Lv2이상 가능)&ndash;&gt;
                            <div class="bonus1" v-if="applyCash.bonusin == sportsConst.APPLY_BONUS_YES2">
                                <div style="font-size: 10px">
                                    <p>
                                        스포츠 <span class="percentStyle">100%</span>
                                        인플레이 <span class="percentStyle">200%</span>
                                        스페셜 <span class="percentStyle">200%</span>
                                    </p>
                                    <p>
                                        단/두폴더 <span class="percentStyle">200%</span>
                                        미니게임 <span class="percentStyle">300%</span>
                                        카지노&슬롯 <span class="percentStyle">300%</span> <span
                                            style="color: skyblue;font-weight: bold">이용가능</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>-->
                    <div class="re_cont">
                        <div class="c1">
                            포인트(%)
                        </div>
                        <div class="c2" style="">
                            <!--롤링 보너스 미적용-->
                            <div class="bonus2" style="color: #f0e28a">
                                <span v-if="applyCash.bonusin == sportsConst.APPLY_BONUS_YES1 && todaystatus == sportsConst.RECHARGE_FIRSTBONUS">{{b1}} %</span>
                                <span v-if="applyCash.bonusin == sportsConst.APPLY_BONUS_YES2 && todaystatus == sportsConst.RECHARGE_FIRSTBONUS">{{b2}} %</span>
                                <span v-if="applyCash.bonusin == sportsConst.APPLY_BONUS_YES3 && todaystatus == sportsConst.RECHARGE_FIRSTBONUS">{{b3}}%</span>
                                <span v-if="todaystatus == sportsConst.RECHARGE_DAYBONUS && applyCash.bonusin != -1 && applyCash.bonusin != sportsConst.APPLY_BONUS_NOT">{{b4}}%</span>
                                <span v-if="(todaystatus == sportsConst.RECHARGE_NOBONUS && applyCash.bonusin != -1 || applyCash.bonusin == sportsConst.APPLY_BONUS_NOT)">0%</span>
                            </div>
                        </div>
                    </div>

                    <div class="re_cont">
                        <div class="c1">
                            포인트
                        </div>
                        <div class="c2">
                            <div style="padding: 10px 0">
                                <div style="display: flex;justify-content: flex-start;align-items: center;color: #fff9ff">
                                    <div style="background-color: #0070c0;padding: 3px 7px;border-radius: 3px">
                                        <input type="radio" name="bonusin" id="bonusin1"
                                               :value="sportsConst.APPLY_BONUS_YES1"
                                               v-model="applyCash.bonusin">
                                        <span style="margin-left: 5px"><label for="bonusin1">지급</label></span>
                                    </div>
                                    <div style="margin-left: 10px;background-color: #ff0000;padding: 3px 7px;border-radius: 3px;color: #fff9ff">
                                        <input type="radio" name="bonusin" id="bonusin2"
                                               :value="sportsConst.APPLY_BONUS_NOT"
                                               v-model="applyCash.bonusin">
                                        <span style="margin-left: 5px"><label for="bonusin2">미지급</label></span>
                                    </div>
                                </div>
                                <!--                                <div style="display: flex;justify-content: flex-start;align-items: center;margin-top: 5px">-->
                                <!--                                    <div>-->
                                <!--                                        <input type="radio" name="bonusin" id="bonusin4"-->
                                <!--                                               :value="sportsConst.APPLY_BONUS_YES3"-->
                                <!--                                               v-model="applyCash.bonusin">-->
                                <!--                                        <span style="margin-left: 5px"><label for="bonusin4" style="color: #ff8901">적용(Lv1 카지노&슬롯)</label></span>-->
                                <!--                                    </div>-->
                                <!--                                    <div style="margin-left: 10px">-->
                                <!--                                        <input type="radio" name="bonusin" id="bonusin3"-->
                                <!--                                               :value="sportsConst.APPLY_BONUS_YES2"-->
                                <!--                                               v-model="applyCash.bonusin">-->
                                <!--                                        <span style="margin-left: 5px"><label for="bonusin3" style="color: green">적용(Lv2이상 가능)</label></span>-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                            </div>
                        </div>
                    </div>

                    <div class="rec_buttons" style="">

                        <button class="b1" @click="recharge(0)">입금신청
                        </button>
                    </div>
                </div>
                <div style="width: 100%;text-align: right">
                    <span style="background-color: #ffa604;padding: 5px 10px;margin-bottom: 10px;color: #fff9ff;border-radius: 3px;cursor: pointer"
                          @click="deleteAll">전체삭제</span>
                </div>
                <div class="re_history">

                    <table cellpadding="0" cellspacing="0" border="0">
                        <colgroup>
                            <col width="27%">
                            <col width="27%">
                            <col width="27%">
                            <col width="15%">
                        </colgroup>
                        <thead>
                        <tr>
                            <th>시간</th>
                            <th>금액</th>
                            <th @click="refreshData()" style="cursor: pointer">상태</th>
                            <th></th>
                        </tr>
                        <tr v-for="(item,indx) in rechargeList" :key="indx">
                            <td>{{item.createTime|datef('MM/DD HH:mm')}}</td>
                            <td>{{item.amount|comma}}</td>
                            <td>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_WAITING">미확인</span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_CHECKING">확인중</span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_COMPLITE">완료</span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_CANCEL"
                                      style="color: red">실패</span>
                            </td>
                            <td>
                  <span
                          style="display: inline-block;width: 50px; height: 20px; line-height: 20px;text-align: center;cursor: pointer;background-color: #ffa604;border-radius: 5px"
                          v-if="item.status !== sportsConst.APPLY_STATUS_WAITING && item.status
                        !== sportsConst.APPLY_STATUS_CHECKING" @click="deleteById(item.id)">
                     삭제
                  </span>

                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <!--<right-bar-comp>
             <user-info-comp slot="userinfo"></user-info-comp>
             <right-buttons-comp slot="btns"></right-buttons-comp>
             <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
             <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
         </right-bar-comp>-->

        <foot-comp></foot-comp>

    </div>

</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import {
        deleteAllApply,
        deleteApply,
        getApplyCashList,
        getTodayRechargeStatus,
        rechargeCash,
        saveQuestion
    } from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {addMemberCasinoCash, getAccountBalance} from "../../network/casinoHonorRequest";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";

    export default {
        name: "Recharge",
        mixins: [postionMixin],
        components: {
            SportsBetCartComp,
            RightButtonsComp,
            UserInfoComp,
            SportsLeftBarComp,
            RightBarBannerComp, TopbarComp, FootComp, LeisureLeftComp, LeftBarComp, RightBarComp, SubTitle
        },
        data() {
            return {
                sportsConst,
                applyAmount: '',
                applyCash: {
                    amount: 0,
                    bonusin: -1,
                    consiterecharge: 0,
                },
                search: {applyType: sportsConst.APPLY_TYPE_RECHARGE},
                rechargeList: [],
                isSampleSite: false,
                casinocash: 0,
                position: "입금",
                b0: 0,
                b1: 0,
                b2: 0,
                b3: 0,
                b4: 0,
                todaystatus: -1,

            }
        },
        methods: {

            setComma() {
                if (this.applyAmount == '') {
                    this.applyAmount = ''
                } else {
                    this.applyAmount = this.$root.$options.filters.comma(this.applyAmount)
                }
            },
            changeMoney(cash) {
                if (cash === 0) {
                    this.applyAmount = '';
                } else {
                    let c = this.applyAmount == ''  ? 0 : this.applyAmount.replaceAll(',', '')
                    this.applyAmount = this.$root.$options.filters.comma(parseInt(c) + parseInt(cash));

                }
            },
            changeCasinoMoney(cash) {
                if (cash === 0) {
                    this.casinocash = 0;
                } else {
                    this.casinocash += parseInt(cash);
                }
            },
            gotocoinsite() {
                window.open(sportsConst.APPLY_COINSITE_URL, '_blank')
            },
            recharge(consiterecharge) {

                this.applyCash.amount = this.applyAmount.replaceAll(',', '');

                this.applyCash.consiterecharge = consiterecharge;
                if (this.applyCash.bonusin === -1) {
                    this.$swal({
                        title: '포인트 선택해주세요',
                        type: 'warning',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false
                }

                this.$swal({
                    title: this.applyCash.amount + ' 입금신청',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        this.$store.commit(RECEIVE_SHOW_LOADING)
                        rechargeCash(this.applyCash).then(res => {
                            if (res.data.success) {
                                this.$swal({
                                    title: '입금신청완료',
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.applyCash.bonusin = 1
                                this.applyCash.amount = 0
                                this.applyCash.consiterecharge = 0;
                                this.applyAmount = ''
                                this.initRechargeList()
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'warning',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                            this.$store.commit(RECEIVE_HIDE_LOADING)
                        })
                    }
                })
            },
            initRechargeList() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getApplyCashList(this.search).then(res => {
                    if (res.data.success) {
                        this.rechargeList = res.data.data
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
            refreshData() {
                this.initRechargeList()
                this.$bus.$emit('applyRequestSuccess')
            },
            deleteById(id) {
                this.$swal({
                    title: '입금내역 삭제',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        deleteApply(id).then(res => {
                            if (res.data.success) {
                                this.initRechargeList()
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'warning',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            deleteAll(id) {
                this.$swal({
                    title: '전부 삭제',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        deleteAllApply(sportsConst.APPLY_TYPE_RECHARGE).then(res => {
                            if (res.data.success) {
                                this.initRechargeList()
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'warning',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },

            getCasinoBalance() {
                getAccountBalance().then(res => {
                    this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                })
            },
            questioAcNo() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                let content = '계좌번호 문의합니다'
                saveQuestion({'content': content, 'type': sportsConst.CUSTOMER_TYPE_BANK_CARD}).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.content = ''
                        this.$swal({
                            title: '계좌번호 문의 되였습니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.$router.push('/customer_center')

                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'warning',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },

            getTodayRechargeStatus() {
                getTodayRechargeStatus().then(res => {
                    this.todaystatus = res.data.data
                    if (this.todaystatus == sportsConst.RECHARGE_DAYBONUS) {
                        this.b4 = this.$store.state.userInfo.rank.dayBonusPercent
                    }
                    if (this.todaystatus == sportsConst.RECHARGE_NOBONUS) {
                        this.applyCash.bonusin = sportsConst.APPLY_BONUS_NOT
                    }
                })
            },
        },
        created() {
            this.getTodayRechargeStatus()
            //this.getCasinoBalance();
            this.initRechargeList()
            try {
                if (this.$store.state.userInfo.rank) {
                    let rb = this.$store.state.userInfo.rank.rechargebonus1.split(",")
                    if (rb.length == 2) {
                        this.b1 = rb[0]
                        this.b2 = rb[1]
                        this.b3 = 5
                    }
                    if (rb.length == 3) {
                        this.b1 = rb[0]
                        this.b2 = rb[1]
                        this.b3 = rb[2]
                    }
                }
            } catch (e) {

            }

        },
        mounted() {

        },
        watch: {
            "applyCash.bonusin": {
                handler(newVal) {
                    if (newVal == sportsConst.APPLY_BONUS_YES3) {
                        this.b4 = 5
                    } else {
                        try {
                            if (this.todaystatus == sportsConst.RECHARGE_FIRSTBONUS) {
                                if (this.$store.state.userInfo.rank) {
                                    let rb = this.$store.state.userInfo.rank.rechargebonus1.split(",")
                                    if (rb.length == 2) {
                                        this.b1 = rb[0]
                                        this.b2 = rb[1]
                                        this.b3 = 5
                                    }
                                    if (rb.length == 3) {
                                        this.b1 = rb[0]
                                        this.b2 = rb[1]
                                        this.b3 = rb[2]
                                    }
                                }
                            }
                            if (this.todaystatus == sportsConst.RECHARGE_DAYBONUS) {
                                this.b4 = this.$store.state.userInfo.rank.dayBonusPercent
                            }
                            if (this.todaystatus == sportsConst.RECHARGE_NOBONUS) {
                                this.b4 = 0
                            }

                        } catch (e) {

                        }
                    }
                },
                immediate: true,
                deep: false
            },
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
    @import url("../../assets/css/reex.css");

    .percentStyle {
        color: #ffa500;
        font-weight: 600
    }
</style>